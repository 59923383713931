@font-face{
    font-family:'Poppins Light';
    src:url('./fonts/poppins/Poppins-Light.ttf') format('truetype');
    font-weight:300;
    font-style:normal;
}
@font-face{
    font-family:'Poppins Light Italic';
    src:url('./fonts/poppins/Poppins-LightItalic.ttf') format('truetype');
    font-weight:300;
    font-style:italic;
}
@font-face{
    font-family:'Poppins';
    src:url('./fonts/poppins/Poppins-Regular.ttf') format('truetype');
    font-weight:400;
    font-style:normal;
}
@font-face{
    font-family:'Poppins Italic';
    src:url('./fonts/poppins/Poppins-Italic.ttf') format('truetype');
    font-weight:400;
    font-style:italic;
}
@font-face{
    font-family:'Poppins SemiBold';
    src:url('./fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
    font-style:normal;
}
@font-face{
    font-family:'Poppins SemiBold Italic';
    src:url('./fonts/poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-style:italic;
}
@font-face{
    font-family:'Poppins Medium';
    src:url('./fonts/poppins/Poppins-Medium.ttf') format('truetype');
    font-style:normal;
}
@font-face{
    font-family:'Poppins Bold';
    src:url('./fonts/poppins/Poppins-Bold.ttf') format('truetype');
    font-style:normal;
}
@font-face{
    font-family:'Poppins Bold Italic';
    src:url('./fonts/poppins/Poppins-BoldItalic.ttf') format('truetype');
    font-style:italic;
}
